import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
    name:"multi_order_sante",
    initialState:{
        order_products:localStorage.getItem('order_array_prod') ? JSON.parse(localStorage.getItem('order_array_prod')) : []
    },
    reducers:{
        addOrderDataProduct:(state,action) =>{
            let productOrder = action.payload;
            productOrder.forEach(prod => {
                let existProd = state.order_products.some(ex => ex.product_id === prod.product_id);
                if(existProd === false){
                    state.order_products.push(prod);
                    localStorage.setItem('order_array_prod', JSON.stringify(state.order_products));
                }else{
                    let indexProd = state.order_products.findIndex(p => p.product_id === prod.product_id);
                    let newQty = state.order_products[indexProd].quantity + prod.quantity;
                    state.order_products[indexProd].quantity = newQty;

                    localStorage.setItem('order_array_prod', JSON.stringify(state.order_products));
                }
            });
        },
        
        removeOrderDataProduct:(state,action)=>{
            let productOrder = action.payload;
            productOrder.forEach(prod => {
                let indexProd = state.order_products.findIndex(p => p.product_id === prod.product_id);
                let newQty = state?.order_products[indexProd]?.quantity - prod.quantity;
                if(newQty > 0){
                    state.order_products[indexProd].quantity = newQty;
                }else{
                    state.order_products.splice(indexProd, 1)
                }

                localStorage.setItem('order_array_prod', JSON.stringify(state.order_products));
            });
        },
        removeAllOrderProd:(state)=>{
            localStorage.removeItem("order_array_prod");
        }
    }
});

export const {addOrderDataProduct,removeOrderDataProduct,removeAllOrderProd} = orderSlice.actions;
export default orderSlice.reducer;