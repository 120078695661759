import { configureStore } from "@reduxjs/toolkit";
import orderSlice from "./orderSlice";
import personsSlice from './personsSlice';
import cartProdSlice from './cartProdSlice';

export default configureStore({
    reducer:{
        multi_order_sante:orderSlice,
        persons_multi_order:personsSlice,
        cart_initial_prod:cartProdSlice
    }
})