import { createSlice } from "@reduxjs/toolkit";
import ShortUniqueId from 'short-unique-id';

export const personsSlice = createSlice({
    name: "persons_multi_order",
    initialState: {
        persons: JSON.parse(localStorage.getItem('sante_persons')) || [
            {
                position: 1,
                id: "8eIIBs1",
                first_name: '',
                last_name: '',
                birth_day: '',
                patient_phone: '',
                cnp: '',
                product: [],
                is_genetica:false,
                tipul_consultatiei:'',
                motivul_consultatiei:'',
                recomandare_consult:'',
                alte_consultatii:false,
                alte_consultatii_textarea:'',
                analize_rezultate_modificate:false,
                analize_rezultate_modificate_textarea:'',
                analize_rezultate_imagistice:false,
                analize_rezultate_imagistice_textarea:'',
                analize_electrofiziologice:false,
                analize_electrofiziologice_textarea:'',
                analize_anatomopatologice:false,
                analize_anatomopatologice_textarea:'',
                analize_genetice:false,
                analize_genetice_textarea:'',
                documente_medicale:false,
                documente_medicale_file:[],
                documente_medicale_suplimentare:false,
                format_consult:'fizic',
                consultare_doctor_ales:'Nu am preferințe',
            }, {
                position: 2,
                id: "UQm61A2",
                first_name: '',
                last_name: '',
                birth_day: '',
                patient_phone: '',
                cnp: '',
                product: [],
                is_genetica:false,
                tipul_consultatiei:'',
                motivul_consultatiei:'',
                recomandare_consult:'',
                alte_consultatii:false,
                alte_consultatii_textarea:'',
                analize_rezultate_modificate:false,
                analize_rezultate_modificate_textarea:'',
                analize_rezultate_imagistice:false,
                analize_rezultate_imagistice_textarea:'',
                analize_electrofiziologice:false,
                analize_electrofiziologice_textarea:'',
                analize_anatomopatologice:false,
                analize_anatomopatologice_textarea:'',
                analize_genetice:false,
                analize_genetice_textarea:'',
                documente_medicale:false,
                documente_medicale_file:[],
                documente_medicale_suplimentare:false,
                format_consult:'fizic',
                consultare_doctor_ales:'Nu am preferințe',
            }
        ],
    },



    reducers: {
        addPerson: (state) => {
            // Check if we have fewer than 10 persons
            if (state.persons.length < 10) {
                const newId = state.persons.length;
                const uid = new ShortUniqueId();
                state.persons.push({
                    position: newId + 1,
                    id: uid.rnd() + newId,
                    first_name: '',
                    last_name: '',
                    birth_day: '',
                    patient_phone: '',
                    cnp: '',
                    product: [],
                    is_genetica:false,
                    tipul_consultatiei:'',
                    motivul_consultatiei:'',
                    recomandare_consult:'',
                    alte_consultatii:false,
                    alte_consultatii_textarea:'',
                    analize_rezultate_modificate:false,
                    analize_rezultate_modificate_textarea:'',
                    analize_rezultate_imagistice:false,
                    analize_rezultate_imagistice_textarea:'',
                    analize_electrofiziologice:false,
                    analize_electrofiziologice_textarea:'',
                    analize_anatomopatologice:false,
                    analize_anatomopatologice_textarea:'',
                    analize_genetice:false,
                    analize_genetice_textarea:'',
                    documente_medicale:false,
                    documente_medicale_file:[],
                    documente_medicale_suplimentare:false,
                    format_consult:'fizic',
                    consultare_doctor_ales:'Nu am preferințe',
                });
                localStorage.setItem('sante_persons', JSON.stringify(state.persons));
            } else {
                console.warn('Cannot add more than 10 persons.');
            }
        },
removePerson: (state, action) => {
    const idToRemove = action.payload;

    // Check if the persons array has at least one person
    if (state.persons.length > 2) {
        // Filter out the person to remove
        const updatedPersons = state.persons.filter(person => person.id !== idToRemove);

        // If the new array length is 0, log a warning
        if (updatedPersons.length === 0) {
            console.warn('Cannot delete the last person.');
        }

        // Update the state
        state.persons = updatedPersons;

        // Persist to localStorage
        localStorage.setItem('sante_persons', JSON.stringify(state.persons));
    } else {
        console.warn('No persons to delete.');
    }
},

        
        // updatePerson: (state, action) => {
        //     const { id, val, key } = action.payload;
        //     //get array index
        //     const indexPerson = state.persons.findIndex(p => p.id === id);
        //     if (indexPerson !== -1) {
        //         const updatePrson = [...state.persons];
                
        //         //update person by key and value
        //         updatePrson[indexPerson] = {
        //             ...updatePrson[indexPerson],
        //             [key]: val
        //         };

        //         //update state
        //         state.persons = updatePrson;
        //         //update local
        //         localStorage.setItem('sante_persons', JSON.stringify(state.persons));
        //     }
        // },

        updatePerson: (state, action) => {
            const { id, val, key } = action.payload;
            const indexPerson = state.persons.findIndex(p => p.id === id);
        
            if (indexPerson !== -1) {
                const updatedPerson = { ...state.persons[indexPerson], [key]: val };
        
                // Check if the is_genetica field was updated and set to false
                if (key === 'is_genetica' && val === false) {
                    // Reset the necessary fields to their default values
                    updatedPerson.tipul_consultatiei = '';
                    updatedPerson.motivul_consultatiei = '';
                    updatedPerson.recomandare_consult = '';
                    updatedPerson.alte_consultatii = false;
                    updatedPerson.alte_consultatii_textarea = '';
                    updatedPerson.analize_rezultate_modificate = false;
                    updatedPerson.analize_rezultate_modificate_textarea = '';
                    updatedPerson.analize_rezultate_imagistice = false;
                    updatedPerson.analize_rezultate_imagistice_textarea = '';
                    updatedPerson.analize_electrofiziologice = false;
                    updatedPerson.analize_electrofiziologice_textarea = '';
                    updatedPerson.analize_anatomopatologice = false;
                    updatedPerson.analize_anatomopatologice_textarea = '';
                    updatedPerson.analize_genetice = false;
                    updatedPerson.analize_genetice_textarea = '';
                    updatedPerson.documente_medicale = false;
                    updatedPerson.documente_medicale_file = [];
                    updatedPerson.documente_medicale_suplimentare = false;
                    updatedPerson.format_consult = 'fizic';
                    updatedPerson.consultare_doctor_ales = 'Nu am preferințe';
                }
        
                // Update the state
                state.persons[indexPerson] = updatedPerson;
        
                // Persist to localStorage
                localStorage.setItem('sante_persons', JSON.stringify(state.persons));
            }
        },
        
        updatePersonProducts: (state, action) => {
            const { id, updates } = action.payload;
            const person = state.persons.find(p => p.id === id);
            if (person) {
                Object.assign(person, updates);
                localStorage.setItem('sante_persons', JSON.stringify(state.persons));
            }
        },
        removeAllPersons:(state)=>{
            localStorage.removeItem("sante_persons");
        }
        
    }
});

export const { addPerson, removePerson, updatePerson,updatePersonProducts,removeAllPersons } = personsSlice.actions;
export default personsSlice.reducer;
